import {
  FeatureLayoutComponent,
  FeaturePageComponent
} from "@pwa/features/page";
import { withAuthentication } from "@pwa/features/page";
import { RootLayout } from "@pwa/features/root";
import Error from "next/error";

const Page404: FeaturePageComponent = () => {
  return <Error statusCode={404} />;
};

const Page404Layout: FeatureLayoutComponent = ({ children }) => <>{children}</>;
Page404Layout.getLayout = (page) =>
  RootLayout.getLayout(<Page404Layout>{page}</Page404Layout>);

Page404.PageLayout = Page404Layout;

export default withAuthentication(Page404);
